import {
    ContentSearchRequestResponseType,
    excludeIdFilter,
    FacetsBodyType,
    getRelewiseContentSearchQuery,
    hydrateFacetsWithSelectedFacets,
    mapResponseFacets,
    noEmptyDataFieldFilter,
    RelewiseContentDataTagsTypes,
    SelectedFacet,
} from '$templates/blocks/components/M140ProductsList';
import { toggleFacetOption } from '$templates/blocks/components/M140ProductsList/components/filters/utils';
import { User } from '@relewise/client';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { ContentSortingEnum, PageTags } from '~/lib/data-contract';
import { fetcher } from '~/lib/fetcher';
import { DropdownItem } from '~/shared/components';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { usePage } from '~/templates/pages';
import { Facet } from '../../M140ProductsList/model';
import {
    categoryFilter,
    sortByPrioritized,
    sortOptions,
    userFilters,
} from '../lib/relewiseFilters';
import { dataFieldValueContainsFilter } from '../../../../pages/api/relewise/request/utils/dataFieldValueContainsFilter';

interface fetchRelevantContentParams {
    pageParam: number;
    pageSize: number;
    locale: string;
    facets: FacetsBodyType[];
    filters: unknown[];
    sort?: ContentSortingEnum;
    pageId: string;
    user?: User;
    usePrioritizedSorting: boolean;
}

export const fetchRelevantContent = async ({
    pageParam,
    pageSize,
    pageId,
    facets,
    filters,
    locale,
    sort,
    user,
    usePrioritizedSorting,
}: fetchRelevantContentParams) => {
    const { RELEWISE_API_URL, RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } =
        publicRuntimeConfig();
    const sortObject = sort && sortOptions.find((option) => option.key == sort);
    let sorting;

    if (usePrioritizedSorting) {
        sorting = {
            ...sortByPrioritized,
            thenBy: sortObject?.relewiseData,
        };
    } else if (sortObject) {
        sorting = sortObject.relewiseData;
    }

    const contentSearchRequest = getRelewiseContentSearchQuery({
        locale: locale,
        take: pageSize,
        skip: pageParam * pageSize,
        term: null,
        filters: [noEmptyDataFieldFilter('Url', 'content'), excludeIdFilter([pageId]), ...filters],
        facets: facets,
        sort: sorting ?? undefined,
        user,
    });

    return fetcher<ContentSearchRequestResponseType>(
        `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/ContentSearchRequest`,
        {},
        { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
        'POST',
        JSON.stringify(contentSearchRequest),
    )
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
};

export const getRelevantContentQueryConfig = ({ pageSize }: { pageSize: number }) => {
    return {
        getNextPageParam: (
            lastPage: ContentSearchRequestResponseType,
            pages: ContentSearchRequestResponseType[],
        ) => {
            const nextPageIndex = pages.length;
            const totalPages = lastPage.hits / pageSize;
            return nextPageIndex < totalPages ? nextPageIndex : null;
        },
        enabled: true,
        keepPreviousData: true,
        cacheTime: 1000 * 60 * 10, // 10 minutes cache time
        staleTime: 1000 * 60 * 10, // 10 minutes cache time
        retryOnMount: false,
    };
};

export const getQueryKey = ({
    selectedSort,
    pageSize,
    tags,
    pageId,
    selectedFacets,
    locale,
    usePrioritizedSorting,
}: {
    selectedSort: ContentSortingEnum;
    pageSize: number;
    pageId?: string;
    tags: PageTags[];
    selectedFacets: SelectedFacet[];
    locale: string;
    usePrioritizedSorting: boolean;
}) => {
    return [
        'RelewiseRelevantContent',
        selectedSort,
        pageSize,
        tags,
        selectedFacets,
        locale,
        pageId,
        usePrioritizedSorting,
    ];
};

export type UseRelewiseRelevantContentParams = {
    tags: PageTags[];
    pageSize: number;
    defaultSorting: ContentSortingEnum;
    usePrioritizedSorting?: boolean;
};

export const useRelewiseRelevantContent = ({
    tags = [],
    pageSize,
    defaultSorting = ContentSortingEnum.PUBLISH_DATE_DESC,
    usePrioritizedSorting = true,
}: UseRelewiseRelevantContentParams) => {
    const { id, market, culture } = usePage();
    const locale = `${culture}-${market}`.toLowerCase();
    const initialFacets: FacetsBodyType[] = [categoryFilter, ...userFilters];
    const { getUserObject } = useRelewiseTracking();
    const filters = tags.map((tag) => {
        const tagValues =
            tag.tagsList?.map(
                (tag) => tag[`${culture}-${market}`]?.trim() || tag['en-INT']?.trim(),
            ) || [];
        return dataFieldValueContainsFilter({
            key: `${tag.title}`.trim(),
            target: 'content',
            matchType: 'Any',
            value: tagValues,
            equals: false,
        });
    });
    const facetButtons = [RelewiseContentDataTagsTypes.Sector, RelewiseContentDataTagsTypes.Theme];
    const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
    const [l2Categories, setL2Categories] = useState<Facet | undefined>();
    const [selectedFacets, setSelectedFacets] = useState<SelectedFacet[]>([]);
    const [selectedSort, setSelectedSort] = useState<ContentSortingEnum>(defaultSorting);

    const queryKey = useMemo(() => {
        return getQueryKey({
            selectedFacets,
            pageSize,
            tags,
            selectedSort,
            locale,
            pageId: id,
            usePrioritizedSorting,
        });
    }, [selectedFacets, pageSize, tags, selectedSort, locale, culture, market, id]);

    const query = useInfiniteQuery<ContentSearchRequestResponseType, Error>(
        queryKey,
        ({ pageParam = 0 }) => {
            const params = {
                pageParam: pageParam,
                locale: locale,
                pageSize: pageSize ?? 0,
                pageId: id,
                facets: hydrateFacetsWithSelectedFacets([...initialFacets], selectedFacets),
                filters: filters,
                sort: selectedSort,
                user: getUserObject(),
                usePrioritizedSorting,
            };
            return fetchRelevantContent(params);
        },
        getRelevantContentQueryConfig({ pageSize }),
    );

    const totalHits = useMemo(() => {
        if (!query?.data?.pages?.[0]?.hits) return 0;
        return query?.data?.pages?.[0]?.hits;
    }, [query?.data]);

    const facets = useMemo(() => {
        if (!query?.data?.pages?.[0]?.facets?.items) return [];
        const mappedFacets = mapResponseFacets(query?.data?.pages?.[0]?.facets?.items);
        return mappedFacets;
    }, [query?.data]);

    useEffect(() => {
        if (!l2Categories && facets) {
            const l2Facet = facets.find(
                (facet) => facet.attribute == RelewiseContentDataTagsTypes.L2Category,
            );
            if (l2Facet) {
                setL2Categories(l2Facet);
            }
        }
    }, [facets, l2Categories]);

    // Facet helpers
    const toggleFacetOptionHelper = (attribute: string, newItem: DropdownItem) => {
        const newSelectedFacets = toggleFacetOption([...selectedFacets], newItem, attribute);
        setSelectedFacets(newSelectedFacets);
    };

    const toggleBooleanFacetOption = (attribute: string, value: boolean) => {
        let newSelectedFacets = [...selectedFacets];

        if (value == false) {
            newSelectedFacets = newSelectedFacets.filter((facet) => {
                return facet.attribute != attribute;
            });
        } else {
            newSelectedFacets.push({
                attribute: attribute,
                items: [
                    {
                        label: '',
                        value: value,
                    },
                ],
            });
        }
        setSelectedFacets(newSelectedFacets);
    };

    const setFacetSelectedValues = (key: string, selected: DropdownItem[]) => {
        const newSelectedFacets = [...selectedFacets];
        const existingFacetTarget = newSelectedFacets.findIndex((item) => item.attribute == key);

        // if facet does not have any selected
        if (existingFacetTarget == -1) {
            newSelectedFacets.push({
                items: selected,
                attribute: key,
            });
        } else {
            newSelectedFacets[existingFacetTarget].items = selected;
        }
        setSelectedFacets(newSelectedFacets);
    };

    const clearSelectedFacetByKey = (key: string) => {
        const newSelectedFacets = [...selectedFacets];
        const targetFacetId = newSelectedFacets.findIndex((item) => item.attribute == key);
        if (targetFacetId == -1) return;
        newSelectedFacets.splice(targetFacetId, 1);
        setSelectedFacets(newSelectedFacets);
    };

    const clearAllFacets = () => {
        // Remove all facet buttons but keep other systems of facets;
        const newSelectedFacets = [...selectedFacets].filter(
            (facet) =>
                facetButtons.includes(facet.attribute as RelewiseContentDataTagsTypes) == false,
        );
        setSelectedFacets(newSelectedFacets);
    };

    const isNoResults = (query.data?.pages?.[0]?.hits ?? 0) == 0 && !query.isLoading;

    return {
        ...query,
        isNoResults,
        facetButtons,
        facets,
        selectedFacets,
        l2Categories,
        selectedSort,
        setSelectedSort,
        setFacetSelectedValues,
        clearSelectedFacetByKey,
        clearAllFacets,
        totalHits,
        toggleBooleanFacetOption,
        toggleFacetOption: toggleFacetOptionHelper,
        showFiltersDrawer,
        setShowFiltersDrawer,
    };
};
